.synergy-dashboard-page {
  .page-header {
    margin-top: 2em;

    .title {
      font-size: 3em;
      margin-bottom: 0.5em;
    }
  }

  .dashboard-header {
    .title {
      font-size: 3em;
      margin-bottom: 0.5em;
    }
  }

  .page-breakdown-section {
    margin-top: 3em;

    .breakdown-values {
      .title {
        text-align: center;
        font-weight: lighter;
        margin-bottom: .3em;
        line-height: 1;
      }

      .value {
        text-align: center;
        margin-bottom: 1.4em;
        line-height: 1;
      }
    }

    .breakdown-button-wrapper {
      width: 22rem;
      margin: 0 auto 3.4rem;

      .breakdown-button {
        padding-left: 2rem;

        .button-arrow {
          width: 2rem;
        }

        &:hover {
          background-color: #cfd4da;
          transition: background-color 250ms;
        }
      }

      .value {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 1280px) {
    .page-breakdown-section {

      .breakdown-values {
        .title {
          font-size: 1.5rem;
          margin-bottom: .5em;
        }

        .value {
          font-size: 2rem;
          margin: 0;
        }

        .top-breakdown {
          margin-top: 1.5em;
        }

        .bottom-breakdown {
          margin-bottom: 4.5em;
        }
      }

      .breakdown-button-wrapper {
        width: 100%;
        padding-left: 2rem;

        .breakdown-button {
          padding: 0;
        }

        .button-arrow {
          width: 2rem;
        }
      }
    }
  }
}
