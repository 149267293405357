@import "src/style/colors";

.synergy-suppliers-page {

  .page-bag-titles {
    margin-bottom: 64px;

    .bag-title {
      text-align: center;
      font-weight: lighter;
    }
  }

  .page-breakdown-viz {
    margin-bottom: 2 * 64px;

    .breakdown-values {
      text-align: center;

      .title {
        font-weight: lighter;
        margin-bottom: .3em;
        line-height: 1;
      }

      .value {
        margin-bottom: 1.4em;
        line-height: 1;
      }
    }

    @media (max-width: 1280px) {
      .breakdown-values {
        .title {
          font-size: 1.5rem;
          margin-bottom: .5em;
        }

        .value {
          font-size: 2rem;
          margin: 0;
        }

        .top-breakdown {
          margin-top: 1.5em;
        }

        .bottom-breakdown {
          margin-bottom: 4.5em;
        }
      }
    }
  }

  $colWidthName: 250px;
  $colWidthType: 110px;
  $colWidthSpend: 95px;

  .synergy-cmp-supplier-table {
    min-height: 30em;

    .MuiTableCell-root {
      line-height: 1;
      padding-left: 0;
      padding-right: 0;

      &.col-dropdown {
        width: 75px;
      }

      &.col-s_name {
        width: $colWidthName;
      }

      &.col-s_type {
        width: $colWidthType;
      }

      &.col-s_spend_combined {
        width: $colWidthSpend;
      }

      &.col-s_spend_master_ds {
        width: $colWidthSpend;
      }

      &.col-s_spend_cmp_ds {
        width: $colWidthSpend;
      }

      &.col-s_categories {
        width: auto;
      }

      &.col-opportunity {
        width: 25px;
      }

      &.cell-sub-table {
        padding: 0 0 0 75px;
      }
    }

    .sub-table {
      .MuiTableCell-head {
        line-height: 2em;
      }

      .MuiTableCell-root {
        &.col-p_name {
          width: $colWidthName + $colWidthType;
        }

        &.col-p_spend_combined {
          width: $colWidthSpend;
        }

        &.col-p_spend_master_ds {
          width: $colWidthSpend;
        }

        &.col-p_spend_cmp_ds {
          width: $colWidthSpend;
        }

        &.col-p_categories {
          width: auto;
        }

        &.col-opportunity {
          width: 25px;
        }
      }
    }
  }
}

div.supplier-opportunity-create-modal {
  // max-width: 1300px;
  width: 900px;

  .value-title {
    font-weight: bold;
  }
}
