.clickable {
  cursor: pointer;
}

svg {
  .hover-overlay {
    fill: none;
    stroke: none;
    pointer-events: all;
    //stroke-width: 2px;
  }

  .pointer-events {
    pointer-events: all;
  }
}
