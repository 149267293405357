@import 'src/style/variables';
@import 'src/style/colors';

.reporting-page {
  padding: $pageMargin;
  min-height: 40em;

  .header {
    margin-top: $pageHeaderMargin;
    //margin-bottom: $pageHeaderMargin;
  }

  .key-value-container {
    .value-warning {
      color: $colorRed;
    }

    &.narrow-margin {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    &.wide-margin {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  .loading-element {
    max-width: 6.5em;
    margin-top: 0.5em;
  }
}
