.approval-page {
  .mithra-table {
    margin-top: 4em;
  }

  .detail-button {
    &.MuiButton-text {
      padding-left: 1.2em;
      padding-right: 1.2em;
    }
  }
}

.taxonomy-approval-page {
  .MuiFormControl-root {
    margin-top: 2em;
  }
}


.edit-model-component.approval-dialog {
  .MuiFormControl-root {
    margin-top: 2em;
  }

  min-width: auto;
  width: 50em;
}
