@import "src/style/colors";

.match-categories-viz {

  $inactiveGray: #707070;
  $initialGray: #707070;
  $activeBlue: $colorMainBlue;
  $highlightedYellow: orange;

  .hidden {
    stroke: transparent;
    fill: transparent;
  }

  .label {
    circle.outer {
      fill: none;
      stroke-width: 1;
      stroke: $inactiveGray;
    }

    circle.inner {
      fill: none;
      stroke: none;
    }

    &.connected-as-init {
      circle.inner {
        //fill: $initialGray;
      }
    }

    &.connected-not-init {
      circle.inner {
        //fill: $highlightedYellow;
      }
    }

    &.selected {
      circle.inner {
        fill: $activeBlue;
      }

      circle.outer {
        stroke: $activeBlue;
      }
    }

    &:hover {
      circle.inner {
        fill: $activeBlue;
      }

      circle.outer {
        stroke: $activeBlue;
      }
    }

    //&:hover {
    //  circle {
    //    stroke: #DEA01E;
    //    stroke-width: 3;
    //
    //    &.connected-as-init {
    //      //fill: gray;
    //    }
    //
    //    &.connected-not-init {
    //      //fill: #0d0c0a;
    //    }
    //  }
    //
    //  &.selected circle {
    //  }
    //}

    //&.selected {
    //  fill: #DEA01E;
    //
    //  circle {
    //    fill: #DEA01E;
    //    stroke: #DEA01E;
    //    stroke-width: 0;
    //  }
    //}
  }

  .curve {
    path {
      stroke-width: 1;
      fill: none;
    }

    &.selected path {
      stroke: $highlightedYellow;
      stroke-width: 3;
    }

    &.initial path {
      stroke: $initialGray;
    }

    &.created path {
      stroke: $activeBlue;
    }
  }

  .category-tree {
    path {
      fill: none;
      stroke: gray;
      stroke-width: 1;
    }

    .icons path {
      fill: none;
      stroke: black;
    }
  }
}
