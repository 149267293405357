@import "src/style/colors";
.data-ingestion {

  data-ingestion-page {
  }

  //.dataset-overview-table-content {
  //  margin-top: 10em;
  //  max-height: 10em;
  //  overflow-y: scroll;
  //}

  h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: lighter;
    color: #193150;
  }

  h4 {
    color: #0C3352;
  }

  h6 {
    font-weight: lighter;
    color: #193150;
  }
}

