@import "src/style/colors";

svg.cmp-breakdown-single-viz {

  .data-bars {
    stroke: none;

    .value-highlighted {
      fill: $colorMainBlue;
    }

    .value-normal {
      fill: rgba(25, 49, 80, 0.2);
    }
  }

  .labels {
    text {
      cursor: default;

      &.title {
        fill: white;
        font-weight: bold;
      }

      &.hover {
        fill: $colorMainBlue;
      }
    }

    .label {
      .hover {
        transition: opacity 300ms ease-out;

        opacity: 0;
      }

      &:hover .hover {
        opacity: 1;
      }
    }
  }

  .flows {
    path {
      stroke: $colorMainBlue;
      fill: rgba(25, 49, 80, 0.4);
    }
  }
}

@media (max-width: 1280px) {
  svg.cmp-breakdown-single-viz {
    text.value {
      font-size: 1.625rem;
    }

    text.hover-title {
      font-size: 1rem;
    }

    text.value.small {
      font-size: 1rem;
    }
  }
}
