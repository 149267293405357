$colorPartsTableHead: #0C3353;
//$colorPartsTableHeadFont: #ffe7c9;
$colorPartsTableHeadFont: #fff;
$colorPartsTableRow1: #fffff;
$colorPartsTableRow2: #ededed;

$colorTableSelected: #DEA01E;

$colorSuppliers: #f5c27c;
$colorSuppliersHighlight: #f8dab2;
$colorSpend: #75a6e6;
$colorSpendHighlight: #b8cbe6;

$colorMainHeader: #193150;
$colorMainHeaderHighlight: #0d4066;

$colorMainBlue: $colorMainHeader;

$colorDashboard: #193150;

$colorRed: #FF5757;
$colorRedHighlight: #ff7373;
$colorRedText: #FFF;
$colorGreen: #06A77D;
$colorGreenHighlight: #4ba68d;
