@import "src/style/colors";

.data-table, .mithra-table {
  .MuiTableRow-head {
    th {
      cursor: default;

      &.col-help {
        cursor: help;
        font-style: italic;
      }
    }
  }

  &.alt-rows {
    tr:nth-of-type(odd) {
      background: $colorPartsTableRow1;
    }

    tr:nth-of-type(even) {
      background: $colorPartsTableRow2;
    }

    tr.MuiTableRow-head {
      background: $colorPartsTableHead;

      .MuiTableCell-head {
        color: $colorPartsTableHeadFont;
      }
    }
  }

  &.extra-narrow-pagination {
    .MuiTablePagination-toolbar {
      min-height: 38px;
    }

    .MuiIconButton-root {
      padding: 4px;
    }
  }

  &.selectable {
    tbody tr {
      cursor: pointer;

      &.selected {
        background-color: $colorTableSelected;
      }
    }
  }

  &.x-design {
    .MuiTableCell-root {
      line-height: 1;
      padding: 0.5em 0.6em;
    }

    .MuiTableCell-head {
      color: #193150;
      border-bottom-color: #193150;
      border-bottom-width: 1px;
    }

    .MuiTableCell-body {
      color: #707070;
    }

    .MuiTableHead-root {
      position: relative;

      .progress-row {
        td {
          height: 4px;
        }
      }
    }

    &.loading {
      .MuiTableCell-body {
        color: rgba(112, 112, 112, 0.8);
      }

      // Also dimm the chips and icons
      .MuiChip-root, .MuiSvgIcon-root {
        opacity: .4;
      }

    }

    tbody tr:first-of-type .MuiTableCell-body {
      //background-color: palegoldenrod;
      //border-bottom-color: yellow;
    }

    &.x-design-narrow {
      .MuiTableCell-root {
        padding: 1px 0.4em;
      }

      .MuiCheckbox-root {
        padding: 6px;
      }

      .MuiButton-sizeMedium {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .MuiTableRow-head {
      .MuiTableSortLabel-root {
        text-decoration: underline;
        text-decoration-style: dotted;
      }
    }
  }
}
