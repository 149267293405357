.filtered-sankey-visualization {
  .small-loading-spinner {
    height: 2em;

    svg {
      height: 100%;
    }
  }

  .loading-mid-page svg {
    margin-top: 5em;
  }
}
