@import 'src/style/variables';

.not-found-page {
  text-align: center;

  h1, p, button {
    margin-top: $pageHeaderMargin;
  }

  min-height: 50em;
}
