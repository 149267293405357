@import "src/style/colors";

.taxonomy-editor-viz {
  margin-bottom: 2em;

  rect.overlay {
    fill: none;
  }

  &.blocked {
    rect.overlay {
      fill: rgba(255, 255, 255, .33);
    }
  }

  rect.bg {
    opacity: 0;
  }

  circle {
    fill: none;
  }

  text {
    user-select: none;
  }

  circle.outer {
    stroke: $colorPartsTableHead;
  }

  > .edit-mode {
    .node-group:hover circle {
      opacity: 1;
    }
  }

  .node-group {
    &:hover {
      .label-group {
        opacity: 1 !important;
      }

      text {
        opacity: 1 !important;
        fill-opacity: 1 !important;
      }

      rect.bar {
        fill: rgba(25, 48, 79, 0.90);
        stroke: rgba(25, 48, 79, 0.90);
        stroke-width: 2px;
      }

      circle.outer {
        stroke-width: 2px;
      }
    }

    &.selected {
      circle.inner {
        fill: $colorPartsTableHead;
      }

      rect.bar {
        // Add this as well
        fill: rgba(25, 48, 79, 0.75);
      }
    }
  }

  .is-navigate {
    cursor: pointer
  }

  //.is-overlap-both rect.bar {
  //  fill: green;
  //}
  //.is-overlap-top rect.bar {
  //  fill: blue;
  //}
  //.is-overlap-bottom rect.bar {
  //  fill: brown;
  //}

  &.debug {
    rect {
      stroke: rgba(0, 0, 0, .5);
      stroke-width: 3;

      &.bg {
        fill: white;
        opacity: 1;
      }
    }

    .is-navigate {
      background-color: yellow;
    }
  }
}
