@import "src/style/colors";

.CashFlowOptimizationIcon {
  .root {
    //transform: translate(-500px, 2000px) rotate(180deg) scaleX(-0.1) scaleY(0.1); // With the 'sparkles'
    transform: translate(-1132px, 2510px) rotate(180deg) scaleX(-0.15) scaleY(0.15); // Without the 'sparkles'
  }
}

.icon.stroke-adj path {
  stroke: black;
}

.ContractIcon.icon path {
  stroke-width: 10px;
}

.ArrowCollapsedIcon.icon path {
  stroke-width: 10px;
}

$medSize: 35px;
$smallSize: 30px;
.composite-icon-wrapper {
  display: flex;
  flex-direction: column;

  .MuiSvgIcon-root {
    width: $medSize;
    height: $medSize;
  }

  .second-icon {
    transform: translateY(-$medSize * .2);
  }

  .rotated-second-icon {
    transform: translateY(-$medSize * .2) rotate(180deg);
  }

  &.small {
    .MuiSvgIcon-root {
      width: $smallSize;
      height: $smallSize;
    }

    .second-icon {
      transform: translateY(-$smallSize * .2);
    }

    .rotated-second-icon {
      transform: translateY(-$smallSize * .2) rotate(180deg);
    }
  }
}

.hack-lighten-borders {
  &.MuiSvgIcon-root, & {
    path {
      stroke: white;
      stroke-width: .6px;
    }
  }
}
