@import "src/style/variables";

.koi-cfo-page {
  .cash-flow-table {
    table {
      table-layout: fixed;
      //min-height: 59em;
      //max-width: 10em;
    }

    .col-s__id {
      width: 6.0em;
    }

    .col-s__annual_spend {
      width: 1.0em;
    }

    .col-p__payment_term__avg {
      width: 1.0em;
    }

    .col-s__payment_term_avg {
      width: 1.0em;
    }

    .col-p__payment_term_diff__avg {
      width: 1.0em;
    }

    .col-p__payment_term_opp__sum {
      width: 1.0em;
    }

    .col-_is_opportunity {
      width: 1.0em;
    }

    margin-bottom: $pageItemMargin;
  }
}
