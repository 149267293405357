$dropDownWidth: 85px;
$catColWidth: 500px;

.categorization-review-table {
  min-height: 60em;

  .MuiTableCell-root {
    line-height: 1;
    padding-left: 0;
    padding-right: 0;

    &.col-dropdown {
      max-width: $dropDownWidth;
      width: $dropDownWidth;
      text-align: left;
    }

    &.col-s_name {
      //background-color: red;
      width: 180px;
    }

    &.col-s_spend {
      //background-color: hotpink;
      width: 100px;
      font-weight: bold;
    }

    &.col-l1s {
      //background-color: green;
      width: 230px;
    }

    &.col-bus {
      //background-color: green;
      width: 115px;
    }

    &.col-s_cats {
      //background-color: blue;
      width: auto;
    }

    &.col-s_YN {
      //background-color: yellow;
      width: 100px;
      min-width: 100px;
      padding-left: 0;
      padding-right: 1em;
    }

    &.cell-sub-table {
      padding: 0;
      padding-left: $dropDownWidth;
    }
  }

  .supplier-row {
    //background-color: orange;
    height: 4.6em;
  }

  .sub-table {
    margin-top: .5em;
    margin-bottom: 3em;

    .MuiTableBody-root .MuiTableCell-root {
      line-height: 1.43;
      padding: 0.5em 0.2em;
    }

    .MuiTableCell-root {
      &.col-p_spend {
        width: 90px;
      }

      &.col-p_context_l {
        width: $catColWidth;
        max-width: 550px;
      }

      &.col-p_input_l {
        width: $catColWidth;
        max-width: 550px;
      }

      &.col-p_suggested_l {
        width: auto;
        max-width: 550px;
      }

      &.col-p_suggested_conf {
        width: 90px;
      }

      &.col-p_YN {
        //background-color: yellow;
        width: 100px;
        min-width: 100px;
        padding-left: 0;
        padding-right: 1em;
      }
    }

    .MuiTableBody-root .col-p_description {
      //font-size: .7rem;
    }
  }

}
