@import "src/style/variables";
@import "src/style/colors";

.categorization-review {

  .single-bar-chart {
    margin-top: 2em;
    margin-bottom: 2em;

    .left-overflow-area {
      padding-right: 1em;
    }

    .bar-wrapper.old .bar {
      fill: #19315033;
    }

    .bar-wrapper.new .bar {
      fill: #193150;
    }
  }

  .bar-chart-container {
    &.l1 {

    }

    &.l2 {
      margin-left: 1.5em;
    }

    &.l3 {
      margin-left: 2em;
    }

    &.l4 {
      margin-left: 2.5em;
    }
  }

  .nav-all-cats-btn {
    margin-top: 2em;
    z-index: 10;
  }

  .single-bar-chart {
    &.uncat {
      color: $colorRed;

      .bar-wrapper.old .bar {
        fill: #FF575733;
      }

      .bar-wrapper.new .bar {
        fill: $colorRed;
      }
    }

    .sub-bar {
      color: $colorRed;
    }
  }

  .key-value-container {
    &.narrow-margin {
      margin-bottom: 0;

      > div {
        // TODO: The following line negates the effects of the SupplierNormalization scss
        margin-bottom: 0;
      }
    }
  }

  .review-filter-bar {
    .typed-search-component {
      &.has-options {
        display: flex;

        .typed-search-field-type {
          min-width: 11em;
          flex: 1;
        }

        .typed-search-field {
          flex: 3;
        }
      }
    }

    .grouped-switch {
      margin-left: 2em;
    }

    margin-top: 2em;
    margin-bottom: 2em;
  }
}

.accept-reject-controls {
  .accept,
  .reject {
    color: #707070;
    margin: 0 .1em;
  }

  .accept.active {
    color: $colorGreen;
  }

  .reject.active {
    color: $colorRed;
  }
}

.part-recat-component {
  .MuiButtonBase-root {
    flex-wrap: wrap;
    justify-content: flex-start;

    .MuiChip-root {
      cursor: pointer;
    }
  }

  .MuiChip-labelSmall {
    line-height: 1.1em;
  }
}
