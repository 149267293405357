@import "src/style/variables";
@import "src/style/colors";
$dropDownWidth: 85px;
$catColWidth: 325px;

.categorization-approval-table {
  min-height: 60em;

  .MuiTableCell-root {
    line-height: 1;
    padding-left: 0;
    padding-right: 0;

    &.col-dropdown {
      max-width: $dropDownWidth;
      width: $dropDownWidth;
      text-align: left;
    }

    &.col-s_name {
      width: 180px;
    }

    &.col-s_spend {
      width: 100px;
      font-weight: bold;
    }

    &.col-l1s {
      width: 230px;
    }

    &.col-bus {
      width: 115px;
    }

    &.col-s_cats {
      width: auto;
    }

    &.col-s_YN {
      width: 100px;
      min-width: 100px;
      padding-left: 0;
      padding-right: 1em;
    }

    &.cell-sub-table {
      padding: 0;
      padding-left: $dropDownWidth;
    }
  }

  .supplier-row {
    height: 4.6em;
  }

  .sub-table {
    margin-top: .5em;
    margin-bottom: 3em;

    .MuiTableBody-root .MuiTableCell-root {
      line-height: 1;
      padding: 0 0.2em;
    }

    .MuiTableCell-root {
      &.col-p_name {
        width: 125px;
      }

      &.col-p_spend {
        width: 90px;
      }

      &.col-p_description {
        width: auto;
      }

      &.col-p_context_1,
      &.col-p_context_2,
      &.col-p_context_3 {
        width: auto;
      }

      &.col-p_alg_l {
        width: $catColWidth;
        max-width: 550px;
      }

      &.col-p_reviewed_l {
        width: $catColWidth;
        max-width: 550px;
      }

      &.col-p_feedback_l {
        width: 130px;
        max-width: 550px;
      }

      &.col-p_context_3 {
        width: 90px;
      }

      &.col-p_YN {
        width: 100px;
        min-width: 100px;
        padding-left: 0;
        padding-right: 1em;
      }
    }

    .MuiTableBody-root .col-p_description {
      //font-size: .7rem;
    }
  }
}
