@import "src/style/variables";
@import "src/style/colors";

.bar-chart-v2 {

  .clickable {
    cursor: pointer;
  }

  g.bar-wrapper {
    pointer-events: all;

    .hover-overlay {
      fill: none;
      stroke: none;
      pointer-events: all;
      //stroke-width: 2px;
    }

    .value-label {
      opacity: 0;
      cursor: default;
      fill: $colorMainBlue;

      &.value-in-bar {
        fill: white;
      }
    }

    &:hover {
      .hover-overlay {
        fill: lightgrey;
      }

      .bar {
        fill: black;
      }

      .value-label {
        opacity: 1;
      }
    }
  }

  g.bar-wrapper.clickable .value-label {
    cursor: pointer;
  }

  .highlight-line {
    stroke-width: 3px;
  }

  .highlight-text {
    text-anchor: middle;
    dominant-baseline: text-top;
  }

  g.axis line {
    stroke: currentColor;
    stroke-width: 1px;
  }

  g.val-axis .tick text {
    cursor: default;
  }
}
