.edit-model-component, .taxonomy-model-component {
  //.MuiButton-root {
  //}
  padding: 2em;
  min-width: 50em;
  min-height: 15em;

  &.move-model-component {
    min-width: 70em;
    min-height: 16em;
  }

  .selection-overview-list {
    margin-top: 2em;
  }

  &.confirm-send-for-approval-component {
    .MuiFormControl-root {
      margin-top: 2em;
    }
  }
}

.taxonomy-editor-page {

  .page-title {
    display: inline;
    font-size: 3.75rem;
  }

  .taxonomy-title {
    margin-left: 1em;
    display: inline;
    font-weight: lighter;
    font-size: 2.125rem;
  }

  .MuiInput-root {
    margin-left: 1em;
  }


  .toolbar-btn,
  .toolbar-composite-btn {
    line-height: 1.2;
    min-height: 3em;
    text-align: left;
    text-transform: none;
  }

  .btn-text {
    width: 5em;
  }

  .two-icon-btn .btn-text {
    width: 4em;
  }

  #taxonomy-editor-button-toolbar {
    .toolbar-btn,
    .toolbar-composite-btn {
      margin-left: 2em;
    }

    #taxonomy-editor-button-settings-popper {
      z-index: 100;

      .overlay-container {
        display: flex;
        flex-direction: column;
      }

      .toolbar-btn {
        margin-left: 0;
        margin-top: 2em;
        background-color: white;

        .btn-text {
          width: 6.5em;
        }
      }
    }
  }

  .history-popper {
    min-width: 6em;
    z-index: 100;
  }

  .categorization-page-content {
    margin-top: 2em;
    min-height: 40em;

    .MuiAlert-root {
      margin-top: 2em;
    }

    .loading-mid-page {
      margin-top: 15em;
    }

    .taxonomy-editor-viz {
      min-height: 40em;
      // margin-top: 2em;
    }

    .wider-button {
      width: 10em;
    }
  }

  .taxonomy-editor-viz {
    width: 100%;
    //border: solid;
  }

  .history-popper {
    ul {
      padding: 0;

      &:hover li {
        background: rgba(67, 97, 133, 0.2);
      }

      li:hover ~ li {
        background: none;
      }
    }
  }

  &.viewer {
    .categorization-page-content {
      margin: 0;
    }

    .taxonomy-editor-viz {
      margin-top: 1em;
    }
  }
}
