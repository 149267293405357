@import "src/style/variables";
@import "src/style/colors";

.accept-reject-buttons {
  .accept,
  .reject {
    color: #707070;
    margin: 0 .1em;
  }

  .accept.active {
    color: $colorGreen;
  }

  .reject.active {
    color: $colorRed;
  }
}
