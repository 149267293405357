@import "colors";

.btn-red.MuiButton-root {
  color: $colorRed;

  &.MuiButton-outlined:not(.Mui-disabled) {
    border-color: $colorRed;
  }
}

.MuiButtonGroup-root {
  $grpTextColor: white;

  .btn-grp-red:not(.Mui-disabled),
  .btn-grp-red.ignore-disabled {
    &.MuiButton-contained {
      color: $grpTextColor;
      background-color: $colorRed;

      &:hover {
        background-color: $colorRedHighlight;
      }
    }

    &.MuiButton-outlined {
      color: $colorRed;
      border-color: $colorRed;

      &:hover {
        color: $grpTextColor;
        background-color: $colorRed;
      }
    }
  }

  .btn-grp-green:not(.Mui-disabled),
  .btn-grp-green.ignore-disabled {
    &.MuiButton-contained {
      color: $grpTextColor;
      background-color: $colorGreen;

      &:hover {
        background-color: $colorGreenHighlight;
      }
    }

    &.MuiButton-outlined {
      color: $colorGreen;
      border-color: $colorGreen;

      &:hover {
        color: $grpTextColor;
        background-color: $colorGreen;
      }
    }
  }
}

.MuiChip-root.color-error {
  color: $colorRedText;
  background-color: $colorRed;
}

#root .MuiBadge-colorError {
  color: $colorRedText;
  background-color: $colorRed;
}

.MuiAlert-root {
  &.MuiAlert-standardInfo {
    background-color: rgba(12, 51, 83, 0.05);

    .MuiAlert-icon {
      color: #0C3353;
    }
  }

  &.MuiAlert-standardSuccess {
    background-color: #07A67B0D;

    .MuiAlert-icon {
      color: $colorGreen;
    }
  }
}

.MuiTypography-root {
  // This will impact all text, and should not be override as it will remove all nuances between elements
  //color: #193150;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
