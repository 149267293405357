@import 'src/style/colors';

.hierarchical-sankey-chart {
  .links {
    fill: none;
    stroke: gray;
    stroke-opacity: .4;

    .link.uncat {
      stroke: $colorRed;
    }
  }

  .nodes .node {

    &.is-leaf rect {
      opacity: .5;
    }

    &.is-root {
    }

    &.selected rect {
    }

    &.clickable {
      cursor: pointer;
    }

    text {
      font-family: sans-serif;
      font-size: 10px;
      cursor: default;

      &.overflow-label {
        opacity: 0;

        &:hover {
          opacity: 1;
        }
      }
    }

    rect {
      fill: #193150;
    }

    &.uncat {
      rect {
        fill: $colorRed;
      }
    }
  }
}
