.parent-suppliers-step {

  .parent-suppliers-toolbar {
    margin-bottom: 1rem;
    //margin-left: 4.3rem;

    .save-progress-wrapper {
      min-width: 5.2em;
      text-align: center;
    }
  }

  .parent-supplier-review-table {

    .MuiTableCell-root {
      line-height: 1.2;
      padding: 0 .8em;
      //padding-left: .5em;
      //padding-right: 0;

      &.col-dropdown {
        .MuiButtonBase-root {
        }
      }

      &.cell-sub-table {
        padding: 0;
      }

      &.xs-padding {
        padding: 0 .15em;
      }
    }

    .main-row {
      height: 1rem;
      //background-color: rgba(25, 49, 79, 0.05);
    }

    .sub-table {
      margin-top: .5rem;
      margin-bottom: 2rem; // Added an extra big margin to show difference between main and sub rows

      .sub-row {
        //height: 4.6em;
        &.master {
          background-color: rgba(25, 49, 79, 1/30);
        }

        &.base {
          background-color: rgba(25, 49, 79, 0.1);
        }
      }

      .MuiTableBody-root .MuiTableCell-root {
        //line-height: 1.43;
        line-height: 1.2;
        //padding: 0.5em 0.2em;
        //padding: 0 .8em;
      }

      .col-choose-parent {
        .MuiAutocomplete-root {
          flex: 1;
          margin-right: 1rem;
          margin-top: .7rem;
          margin-bottom: .7rem;
        }

        .accept-reject-buttons {
          margin-top: 1.2rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
