// SPACING
$s1: 4px;
$s2: 8px;
$s3: 12px;
$s4: 16px;
$s5: 20px;
$s6: 24px;
$s7: 28px;
$s8: 32px;

$narrow: $s2;
$medium: $s4;
$wide: $s8;

// Page
$pageMargin: $medium;
$pageHeaderMargin: $wide; // Actual += $pageMargin
$pageItemMargin: 2 * $wide;
