$scrollbarWidth: 17px;

.taxonomy-mapper-page {
  /*
  // Very hacky way to align the header correctly...
  .header {
    .MuiGrid-grid-xs-8 {
      margin-left: auto;
      padding-right: $scrollbarWidth;
      -webkit-flex-basis: 41.666667%;
      -ms-flex-preferred-size: 41.666667%;
      flex-basis: 41.666667%;
      max-width: 41.666667%;
    }
  }
   */

  &.koi-facade {
    // Remove the bottom margin
    padding-bottom: 0;
    margin-bottom: -16px;
  }

  .taxonomy-titles {
    margin-top: 2em;
    margin-bottom: 1em;
    padding-right: $scrollbarWidth;

    .taxonomy-title {
      font-size: 2rem;
      text-align: center;
      font-weight: lighter;
    }
  }

  .taxonomy-mapper-main {
    height: calc(100vh - 24px);

    .taxonomy-mapper-main-header {
      padding-top: 1em;
      height: 19%; // Magic number such that the toolbar is into view
      padding-right: $scrollbarWidth;
    }

    .taxonomy-mapper-main-body {
      height: 81%;
    }

    #taxonomy-mapper-viz-wrapper {
      overflow-y: auto;
      //overscroll-behavior-y: contain;
      scroll-snap-type: y proximity;
      height: 100%;
    }
  }

  .taxonomy-mapper-supplier-context-table {
    .MuiTableCell-root {
      line-height: 1;
      padding-left: 0;
      padding-right: 0;
    }

    .supplier-row {
      height: 2.7em;
    }

    .no-data-yet {
      height: 4em;
    }

    .MuiTableBody-root {
      min-height: 1em;
    }

    .MuiTablePagination-toolbar {
      justify-content: end;

      .MuiTablePagination-spacer {
        flex: 0;
      }
    }
  }

  .taxonomy-mapper-toolbar {

    .right-container, .left-container {
    }

    .MuiButton-root {
      min-width: 50px;
      min-height: 50px;
      font-size: 1rem;

      &.connect-button {
        padding: 0 1.5em;
      }

      &.remaining-filter-button {
        padding: 0;

        svg {
          font-size: 1.7rem;
        }

        margin-left: 1em;
        margin-top: -4px; // No idea where this comes from...
      }
    }

    .level-selection-group {
      .MuiButton-root {
        font-size: 1rem;
        padding: 0;
        line-height: 1em;
      }

      .last-selected {
        span.text {
          border-bottom: 1px solid;
        }
      }
    }

    .search-field {
      margin-top: 0.5em;
    }

    .automatic-folding-control {
      margin-top: 6px;

      .label span {
        display: block;
        line-height: 1;
      }
    }
  }

  .match-categories-viz {
  }
}
