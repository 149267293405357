@import "src/style/colors";

.match-categories-viz-v2 {

  .nodes {
    text {
      font-size: 14px;
    }
  }

  $inactiveGray: #707070;
  $initialGray: #707070;
  $activeBlue: $colorMainBlue;
  $highlightedYellow: orange;

  .hidden {
    stroke: transparent;
    fill: transparent;
  }

  .label {
    // by default a rect
    circle {
      stroke: none;
      fill: none;
    }

    rect.inner {
      fill: none;
      stroke: none;
    }

    rect.outer {
      fill: none;
      stroke-width: 1px;
      stroke: $inactiveGray;
    }

    &.left:not(.leaf) {
      // But the un selectables are a circle
      rect.inner, rect.outer {
        stroke: none;
        fill: none;
      }

      circle.inner {
        fill: none;
        stroke: none;
      }

      circle.outer {
        fill: none;
        stroke-width: 1px;
        stroke: $inactiveGray;
      }
    }

    &.left:not(.leaf) {
      //circle.inner {stroke: none;fill: none;}
      //circle.outer {stroke: none;}
      //circle.outer {
      //  opacity: .5;
      //}
    }

    &.left.not-connected.leaf {
      rect.inner {
        fill: #707070;
        opacity: .3;
      }
    }

    &.selected {
      // by default a rect
      &, &.left.not-connected.leaf {
        rect.inner {
          fill: $activeBlue;
          opacity: 1;
        }
      }

      rect.outer {
        stroke: $activeBlue;
      }

      &.left:not(.leaf) {
        rect.inner, rect.outer {
          fill: none;
          stroke: none;
        }

        // But the un selectables are a circle
        circle.inner {
          fill: $activeBlue !important;
          opacity: 1 !important;
        }

        circle.outer {
          stroke: $activeBlue;
        }
      }
    }

    &:hover {
      // by default a rect

      rect.inner {
        fill: $activeBlue;
        opacity: 1;
      }

      rect.outer {
        stroke: $activeBlue;
      }

      &.left:not(.leaf) {
        rect.inner, rect.outer {
          fill: none;
          stroke: none;
        }

        circle.inner {
          fill: $activeBlue !important;
          opacity: 1 !important;
        }

        circle.outer {
          stroke: $activeBlue;
        }
      }

      &.left:not(.leaf) {
        //circle.inner {fill: $activeBlue;stroke: none;}
        //circle.outer {fill: none;stroke: none;}
        //circle.inner {fill: none;stroke: none;}
        //circle.outer {fill: $activeBlue;stroke: none;}
      }
    }
  }

  .curve {
    path {
      stroke-width: 1;
      fill: none;
    }

    &.selected path {
      stroke: $highlightedYellow;
      stroke-width: 3;
    }

    &.initial path {
      stroke: $initialGray;
    }

    &.created path {
      stroke: $activeBlue;
      stroke-width: 2;
    }
  }

  .category-tree {
    path {
      fill: none;
      stroke: gray;
      stroke-width: 1;
    }

    .icons path {
      fill: none;
      stroke: black;
    }

    .highlighted text {
      fill: $highlightedYellow;
      text-decoration: underline;
    }

    .selected, .child-selected {
      text {
        font-weight: bold;
      }
    }
  }
}
