.taxonomy-mapper-review-page {

  section {
    margin-top: 4em;

    > header {
      margin-bottom: 2em;

      .title {
        font-size: 2rem;
      }
    }

  }

  section.unmatched {
    > header {
      max-width: 44em;

      .title {
        margin-bottom: .5em;
      }
    }
  }

  section.matched {
    .taxonomy-titles {
      margin-top: 1em;

      .taxonomy-title {
        font-size: 1.5rem;
        font-weight: lighter;
      }
    }
  }

  .taxonomy-mapper-review-unmatched-table {
    &.MuiTable-root {
      width: auto;
      min-width: 20em;
    }
  }

  .taxonomy-mapper-review-table {
    .col-result {
      text-align: center;
    }

    .col-spacer-left {
      width: 50%;
    }

    .col-spacer-right {
      width: 50%;
    }

    .MuiTableBody-root {
      .col-result {
        border-left: 1px solid;
      }
    }
  }
}
