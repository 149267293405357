@import "../../style/colors";

.opportunities-trello-page {
    min-width: 112em;

    .page-item .MuiTypography-root {
        color: $colorMainBlue;
    }

    .button-pane {
        margin-top: 7em;

        button {
            margin-bottom: 1em;
            font-size: 1.2rem;
            padding: 0.5em 1em;
        }
    }

    .opportunities-trello-pane-container {
        //margin-top: 5em;
        background-color: #fafbfc;
        min-height: 40em;

        .MuiListSubheader-root {
            padding-left: 0;
            padding-right: 0;
        }

        .MuiList-root {
            padding-top: 0;
            padding-bottom: .7em;
        }

        .page-header-item {
            background-color: $colorMainBlue;
            width: 100%;
            padding: .7em 0;

            //background-color: #e6e8ec;

            .pane-header {
                color: white;
                //color: $colorMainBlue;
                text-align: center;
            }

            margin-bottom: .7em;
        }

        .trello-list-item {
            .MuiPaper-root {
                border: 1px solid $colorMainBlue;
            }

            .trello-header-line,
            .trello-value-line {
                //text-align: center;
                margin-left: .5em;
            }

            .trello-header-line {
                color: #525252;
            }

            .trello-value-line {
                font-weight: bolder;
            }

            .MuiCardContent-root {
                padding-top: 1em;
                padding-bottom: 1em;
            }
        }
    }

    .opportunities-trello-pane {
        min-width: 30em;
    }
}
