.single-bar-chart {
  position: relative;
  //background-color: seagreen;
  line-height: 0;

  .left-overflow-area {
    position: absolute;
    right: 100%;
    left: -30%;
    top: 0;
    bottom: 0;
    //background-color: lightpink;
  }

  &.hover-labels svg {
    .bar-wrapper .value-label {
      fill: none;
    }

    .bar-wrapper:hover .value-label {
      fill: currentColor;
    }
  }

  &.hack-hide-old svg {
    .bar-wrapper.old .value-label {
      display: none;
    }
  }
}
