.pareto-chart {
  path {
    fill: none;
  }

  .data-bars {
    .bar-wrapper {
      pointer-events: all;

      .bar-label {
        //display: none;
        opacity: 0;
      }

      &:hover {
        .hover-overlay {
          fill: lightgrey;
        }

        .bar-parts.active {
          rect {
            fill: black;
          }
        }

        .bar-label {
          //display: block;
          opacity: 1;
        }
      }
    }
  }

  g.data-curve, g.fit-curve {
    path {
      fill: none;
    }
  }

  g.data-curve {
    path {
      stroke-width: 4px;
    }
  }

  g.fit-curve {
    path {
      stroke-width: 2px;
    }
  }

  .crosshair-line {
    .crosshairX {
      stroke: red;
    }

    .crosshairY {
      stroke: red;
    }

    .crosshair {
      fill: none;
      stroke-width: 1px;
    }
  }

  .red-line {
    stroke: red;
  }

  .red-line-label {
    fill: red;
  }

  .axis {
    line {
      stroke: currentColor;
    }
  }
}
